import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';

import { UserType } from '@app/models/users/user-profile.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { IdFullNameWidgetResponse } from "@app/models/response.model";

interface Params {
  profile_types: UserType[];
  nurse_only?: 'yes' | 'no';
  limit?: number;
  lookup_field?: string;
  search?: string;
}


@Injectable({
  providedIn: 'root'
})
export class PhysiciansOptionsLoader implements OptionsLoader {
  constructor(private httpService: HttpService) {
  }

  getOptions(params?: Params): Observable<PaginatedResponse<IdFullNameWidgetResponse>> {
    return this.httpService.GET(URLS.physician, {
      widget: 'fk',
      lookup_field: 'full_name',
      limit: 15,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
