import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Directive, Input, ChangeDetectorRef, inject, AfterViewInit, HostBinding } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OptionsLoader, OptionsLoadStrategy } from './models';
import { NotificationsService } from '@app/core/services';
import { QueryBuilder } from '@app/core/query-builder';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@UntilDestroy()
@Directive()
export abstract class BaseSelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() bindValue: string = 'id';
  @Input() bindLabel: string = 'name';
  @Input() optionsLoader: OptionsLoader;
  @Input() loadStrategy: OptionsLoadStrategy = 'onFocus';
  @Input() requestParams: object;
  @Input() @HostBinding('class') size: 'small' | 'medium' = 'medium';

  @Input() set initialOptions(options: any | object[]) {
    if (!this.optionsWereLoadedAtLeastOnce) {
      this.options = options ? (Array.isArray(options) ? options : [options]) : [];
      this.updateInputView();
    }
  }

  options: object[] = [];
  loading: boolean = false;
  value: any;
  optionsWereLoadedAtLeastOnce = false;
  totalItemsWithoutFiltering: number = 0;

  protected readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly notificationsService: NotificationsService = inject(NotificationsService);
  protected onChangeCallback: any = () => null;
  protected onTouchedCallback: any = () => null;
  protected lastRequestParams: object;
  protected wasFocused = false;
  protected readonly SEARCH_DEBOUNCE_TIME = 500;

  private subscription: Subscription;

  protected abstract updateInputView(): void;

  ngAfterViewInit(): void {
    if (this.loadStrategy === 'onInit') {
      this.loadOptions();
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateInputView();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onFocus(): void {
    if (this.loadStrategy !== 'onFocus') {
      return;
    }

    if (!this.wasFocused || this.isParamsChange()) {
      this.loadOptions();
    }

    this.wasFocused = true;
  }

  protected loadOptions(params?: object): void {
    this.subscription?.unsubscribe();
    this.loading = true;
    this.cdr.markForCheck();

    this.subscription = this.optionsLoader.getOptions({ ...(params ?? {}), ...(this.requestParams ?? {}) }).pipe(
      finalize(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe((response) => {
      if (!this.optionsWereLoadedAtLeastOnce || this.isParamsChange()) {
        this.totalItemsWithoutFiltering = response.count;
      }
      this.onOptionsLoad(response);
      this.lastRequestParams = this.requestParams;
      this.optionsWereLoadedAtLeastOnce = true;
    }, error => {
      this.notificationsService.showError(error);
    });
  }

  protected onOptionsLoad(response: PaginatedResponse): void {
    this.options = response.results;
    this.updateInputView();
  }

  private isParamsChange(): boolean {
    return !QueryBuilder.isParamsSame(this.requestParams, this.lastRequestParams);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  trackByOption(_, option): string | number {
    return option[this.bindValue];
  }
}
