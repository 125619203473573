<form [formGroup]="form">
  <div class="form__item">
    <span class="form__label">{{ 'visitDetails.tabVisit_field_reason' | translate }}</span>
    <div class="form__value">
      <app-autocomplete-field
        size="small"
        bindLabel="value"
        bindValue="id"
        formControlName="reason"
        #reasonControl="ngControl"
        [matTooltip]="'visitDetails.tabVisit_field_reason_tooltip' | translate"
        [requestParams]="{ dictionary: 'visit_note_reason' }"
        [optionsLoader]="dictionariesOptionsLoader"
        [clearable]="false"
        [invalid]="reasonControl.touched && reasonControl.invalid"
      ></app-autocomplete-field>

      <app-errors [field]="reasonControl"></app-errors>
    </div>
  </div>

  <div class="form__item">
    <span class="form__label">{{ 'visitDetails.tabVisit_field_actionTaken' | translate }}</span>
    <div class="form__value">
      <app-autocomplete-field
        size="small"
        bindLabel="value"
        bindValue="id"
        formControlName="action"
        #actionControl="ngControl"
        [matTooltip]="'visitDetails.tabVisit_field_actionTaken_tooltip' | translate"
        [requestParams]="{ dictionary: 'visit_note_action' }"
        [clearable]="false"
        [optionsLoader]="dictionariesOptionsLoader"
        [invalid]="actionControl.touched && actionControl.invalid"
      ></app-autocomplete-field>

      <app-errors [field]="actionControl"></app-errors>
    </div>
  </div>

  <div class="form__item notes">
    <span class="form__label">{{ 'visitDetails.tabVisit_field_note' | translate }}</span>
    <div class="form__value textarea-field">
      <div class="flex-grow-1">
        <textarea
          placeholder="{{ 'visitDetails.tabVisit_field_note_placeholder' | translate }}"
          class="form-control"
          formControlName="text"
          #textControl="ngControl"
          autocomplete="off"
          maxlength="1000"
          [class.is-invalid]="textControl.touched && textControl.invalid"
          [matTooltip]="'visitDetails.tabVisit_field_note_tooltip' | translate"
        ></textarea>

        <app-errors [field]="textControl"></app-errors>
      </div>

      <ng-container *ngIf="textControl.value || actionControl.value || reasonControl.value">
        <button class="action__btn" matRipple (click)="submit()">
          <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
        </button>

        <button class="action__btn" matRipple (click)="resetForm()">
          <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>

</form>
