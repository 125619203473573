import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { HttpService } from '@app/core/services';
import { DiagnoseDetail } from '@app/models/patient/diagnosis.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';
import { toPaginatedResponse } from '@app/core/rx-operators';

interface Params {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DiagnosesOptionsLoader implements OptionsLoader {
  constructor(
    private httpService: HttpService
  ) {}

  getOptions(params?: Params): Observable<PaginatedResponse<DiagnoseDetail>> {
    return this.httpService.GET<PaginatedResponse<DiagnoseDetail>>(URLS.diagnosis, {
      widget: 'fk',
      no_limits: 'yes',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
