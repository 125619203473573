import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { DutiesService } from '@app/core/services';
import { DutyCategory } from '@app/models/duties/category.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

interface Params {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CategoriesOptionsLoader implements OptionsLoader {
  constructor(private dutiesService: DutiesService) {
  }

  getOptions(params?: Params): Observable<PaginatedResponse<DutyCategory>> {
    return this.dutiesService.getCategories({
      widget: 'fk',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
