import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { URLS } from '@app/shared/urls';
import { ContentTypesResponse, Login, LoginResponse, NormalizedContentTypes } from '@app/models/login/login.model';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { MessageCenterAuthService } from '@app/pages/message-center/services';
import { ProfilePermissions } from '@app/models/settings/permissions.model';
import { AuthProfileInfo, UserProfile } from '@app/models/users/user-profile.model';
import { HttpService } from '@app/core/services';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
const PROFILE_STORAGE_KEY = 'profile';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  // tslint:disable-next-line:variable-name
  private _profile: AuthProfileInfo;

  get profile(): AuthProfileInfo {
    return this._profile || JSON.parse(localStorage.getItem(PROFILE_STORAGE_KEY)) || {};
  }

  constructor(
    private http: HttpService,
    private messageCenterAuthService: MessageCenterAuthService,
  ) {
    this.token = this.getToken();
    this._profile = this.profile;
  }

  login(data: Login): Observable<LoginResponse> {
    return this.http.POST<LoginResponse>(URLS.login, data).pipe(
      switchMap((loginResponse) => this.handleLoginResponse(loginResponse)),
    );
  }

  handleLoginResponse(response: LoginResponse): Observable<LoginResponse> {
    this.saveToken(response.token);

    return forkJoin([
      this.initProfile(response),
      this.messageCenterAuthService.authorize(),
    ]).pipe(mapTo(response));
  }

  logout(): void {
    this.token = undefined;
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    this._profile = undefined;
    localStorage.removeItem(PROFILE_STORAGE_KEY);
  }

  isAuthorized(): boolean {
    return !!this.getToken() && !!this.profile?.id;
  }

  getToken(): string {
    return this.token || localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  updateProfile(profile: Partial<AuthProfileInfo>): void {
    this.saveProfile({ ...this.profile, ...profile });
  }

  private saveToken(token: string): void {
    this.token = token;
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  }

  private saveProfile(profile: AuthProfileInfo): void {
    this._profile = profile;
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profile));
  }

  private initProfile(loginResponse: LoginResponse): Observable<any> {
    return forkJoin([
      this.loadUserDetails(loginResponse.id),
      this.loadUserPermissions(loginResponse.id),
    ]).pipe(
      tap(([userDetails, permissions]) => this.saveProfile({
        id: loginResponse.id,
        companyId: loginResponse.company,
        contentTypes: this.normalizeContentTypes(loginResponse.content_types),
        permissions: permissions.permissions,
        features: permissions.features,
        fullName: userDetails.full_name,
        avatar: userDetails.avatar,
      }))
    );
  }

  private loadUserDetails(userId: number): Observable<UserProfile> {
    return this.http.GET<UserProfile>(`${ URLS.profile }${ userId }/?owner=yes`);
  }

  private loadUserPermissions(id: number): Observable<ProfilePermissions> {
    return this.http.GET<ProfilePermissions>(`${ URLS.profile }${ id }/permissions/`);
  }

  private normalizeContentTypes(values: ContentTypesResponse): NormalizedContentTypes {
    return Object.entries(values).reduce((acc, [value, key]) => {
      acc[key] = Number(value);
      return acc;
    }, {} as NormalizedContentTypes);
  }
}
