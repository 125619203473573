import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { CaregiverBatch } from '@app/models/users/caregiver-paycheck.model';
import { toPaginatedResponse } from '@app/core/rx-operators';

interface Params {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BillingBatchesOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  getOptions(params?: Params): Observable<PaginatedResponse<CaregiverBatch>> {
    return this.httpService.GET(URLS.payer_batch, {
      widget: 'fk',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
