<div class="message" [class.not-sent]="message.status === MessageStatus.notSent">
    <div class="message__body">
        <div class="message-header">
            <div *ngIf="showSender" class="sender">
                <app-user-avatar class="sender__avatar"
                                 [name]="message.sender.short_name"
                                 [avatar]="message.sender.avatar"
                                 radius="6px"
                                 iconSize="26px"
                ></app-user-avatar>

                <div class="sender__name">
                    {{ message.sender.short_name }}
                </div>
            </div>

            <div *ngIf="!!message.created_at" class="creation-time" [class.right]="!showSender">{{ creationFormattedTime }}</div>
        </div>

        <div class="message__data">
            <div *ngIf="!isThread" class="actions">
                <span *ngIf="message.channel === MessageType.internal && isMine"
                      class="material-icons-two-tone action-icon delete-icon"
                      [matTooltip]="'messageCenter.deleteMessage' | translate"
                      (click)="deleteMessage(message.id)">delete</span>

                <mat-icon class="action-icon replay-icon"
                          [class.resending]="resending"
                          [matTooltip]="'messageCenter.resendMessage' | translate"
                          (click)="resendMessage(message.id)">replay</mat-icon>

                <span *ngIf="!isMine"
                      class="material-icons-two-tone action-icon thread-icon"
                      [matTooltip]="'messageCenter.replyInThread' | translate"
                      (click)="answerToThread(message)">question_answer</span>
            </div>

            <div class="message__text">
               <span class="message-subject">
                <mat-icon *ngIf="message.channel === MessageType.internal"
                          class="material-icons-two-tone channel-icon"
                          [matTooltipShowDelay]="500"
                          [matTooltip]="'messageCenter.messageType_text' | translate">chat</mat-icon>
                <mat-icon *ngIf="message.channel === MessageType.email"
                          class="material-icons-two-tone channel-icon send-email"
                          [matTooltipShowDelay]="500"
                          [matTooltip]="'messageCenter.messageType_email' | translate">email</mat-icon>
                <ng-container *ngIf="message.channel === MessageType.sms">
                    <mat-icon class="material-icons-two-tone channel-icon sms-done"
                              [matTooltipShowDelay]="500"
                              [matTooltip]="'messageCenter.messageType_sms' | translate">sms</mat-icon>

                    <mat-icon class="material-icons-two-tone channel-icon sms-failed"
                              [matTooltipShowDelay]="500"
                              [matTooltip]="'messageCenter.messageType_sms' | translate">sms_failed</mat-icon>
                </ng-container>

                <ng-container *ngIf="message.subject">{{ message.subject }}</ng-container>
               </span>

              <br *ngIf="message.subject">

              <span>{{ message.text }}</span>
            </div>
            <div *ngIf="message.files.length" [style.height.px]="setFilesHeight()">
                <div class="message__file" *ngFor="let file of message.files; index as i">
                    <app-chat-image
                            *ngIf="isImage(file.content_type)"
                            [imageUrl]="file.file"
                            (clickOnImage)="openImageView(file.id)"
                    ></app-chat-image>
                    <app-chat-doc
                            *ngIf="isDocument(file.content_type)"
                            [contentType]="file.content_type"
                            [docUrl]="file.file"
                    ></app-chat-doc>
                </div>
            </div>

        </div>

        <div *ngIf="!!message.replies_info.messages_count && !isThread" class="message__replies replies">
            <app-user-avatar *ngFor="let user of message.replies_info.users; trackBy: trackById"
                             class="sender__avatar"
                             [name]="user.short_name"
                             [avatar]="user.avatar"
                             radius="6px"
                             iconSize="18px"
            ></app-user-avatar>

            <span class="replies__count" (click)="answerToThread(message)">
                {{ message.replies_info.messages_count }} {{ 'messageCenter.replies' | translate }}
            </span>
            <span *ngIf="!!message.replies_info.last_message_time" class="replies__time">
                {{ getFormattedTime(message.replies_info.last_message_time) }}
            </span>
        </div>

        <div class="check {{ getMessageDeliveryStatus() }}"></div>
    </div>
</div>

<div *ngIf="showDivider" class="divider"></div>
