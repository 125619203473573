import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { PayerBatchesService } from '../services';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ServiceWidgetWithUniqueSelector } from '@app/models/service.model';
import { toPaginatedResponse } from '@app/core/rx-operators';

@Injectable({
  providedIn: 'root'
})
export class PayerServicesOptionsLoader implements OptionsLoader {
  constructor(private payerBatchesService: PayerBatchesService) {
  }

  getOptions(params?: { payers: number[] }): Observable<PaginatedResponse<ServiceWidgetWithUniqueSelector>> {
    return this.payerBatchesService.getPayerServices(params).pipe(toPaginatedResponse());
  }
}
