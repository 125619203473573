import { FullAddressIfc } from '@app/shared/interfaces/full-address.ifc';

export interface Payer {
  id: number | null;
  number: string;
  name: string;
  avatar: string | null;
  offices: Array<number>;
  offices_details: Array<any>;
  contact_person: string;
  email: string;
  fax: string;
  effective_date: string;
  expiration_date: string;
  icd_code: number;
  icd_code_value: string;
  npi: string;
  phones: any;
  phone_numbers: any;
  provider_id: string;
  status: PayerStatus;
  status_value: string;
  tax_id: string;
  auth_required: boolean;
  address_data: FullAddressIfc;
  address_detail: FullAddressIfc;
}

export enum PayerStatus {
  Active,
  Inactive
}

export enum PayerType {
  EDI,
  PrivatePay
}
