import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PayerWidget } from '@app/models/widgets.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

interface Params {
  search?: string;
  lookup_field?: string;
  patient_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PayersOptionsLoader implements OptionsLoader {

  constructor(private httpService: HttpService) {}

  getOptions(params?: Params): Observable<PaginatedResponse<PayerWidget>> {
    return this.httpService.GET(URLS.payer, {
      widget: 'fk',
      lookup_field: 'name',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
