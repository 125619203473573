import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '@app/core/services';
import { DictionariesOptionsLoader } from '@app/core/field-options-loaders';
import { VisitNote } from '@app/models/patient/visit-details/visit.model';

@Component({
  selector: 'app-visit-note-form',
  templateUrl: 'visit-note-form.component.html',
  styleUrls: ['visit-note-form.component.scss'],
})
export class VisitNoteFormComponent implements OnInit {
  @Output() addNote = new EventEmitter<VisitNote>();

  form: FormGroup;

  readonly dictionariesOptionsLoader = new DictionariesOptionsLoader();

  constructor(private formService: FormService) {
  }

  ngOnInit(): void {
    this.form = new FormBuilder().group({
      text: ['', [Validators.required]],
      reason: [null, [Validators.required]],
      action: [null, [Validators.required]]
    });
  }

  submit(): void {
    this.formService.validateForm(this.form);
    if (this.form.invalid) {
      return;
    }

    this.addNote.emit(this.form.value);
    this.resetForm();
  }

  resetForm(): void {
    this.form.reset();
  }
}
