import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DispatchScreenService,
  FormService,
  NotificationsService,
  UserService,
  VisitDetailsService
} from '@app/core/services';
import {
  VisitConfirmationType,
  VisitDetailsInfoUpdate,
  VisitDetailsVisitInfo,
  VisitNote
} from '@app/models/patient/visit-details/visit.model';
import { UserType } from '@app/models/users/user-profile.model';
import { VisitTabFormComponent } from '@app/pages/visit-details/models';
import { pad } from '@app/shared/helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VisitStatus } from '@app/models/visits/visits.model';
import { filter, finalize } from 'rxjs/operators';
import { DurationPipe } from '@app/shared/pipes/duration.pipe';

interface POC {
  id: number;
  duty: number;
  categoty: number;
  categoryName: string;
  dutyName: string;
  dutyCode: string;
  minutes: number;
  done: boolean;
  not_done: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-visit-tab',
  templateUrl: './visit-tab.component.html',
  styleUrls: ['./visit-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitTabComponent implements VisitTabFormComponent, OnInit {
  @Input() visitId: number;
  @Input() visitStatus: number;

  isLoading: boolean;
  visitData: VisitDetailsVisitInfo;
  form: FormGroup;
  isSaving: boolean;

  supervisorList: { value: number, label: string }[] = [];
  verifiedList = [
    { value: 0, label: 'Patient' },
    { value: 1, label: 'Caregiver' }
  ];

  notesList = [];
  pocList: POC[] = [];
  visitsDuration: string;
  resetOverTime: boolean = false;

  readonly VisitStatus = VisitStatus;

  constructor(
    private fb: FormBuilder,
    private visitDetailsService: VisitDetailsService,
    private formService: FormService,
    private userService: UserService,
    private dispatchScreenService: DispatchScreenService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) {
  }

  get visitStart(): FormControl {
    return this.form.get('visitStart') as FormControl;
  }

  get visitEnd(): FormControl {
    return this.form.get('visitEnd') as FormControl;
  }

  get overtimeHours(): FormControl {
    return this.form.get('overtimeHours') as FormControl;
  }

  get overtimeMinutes(): FormControl {
    return this.form.get('overtimeMinutes') as FormControl;
  }

  get timeVerified(): FormControl {
    return this.form.get('timeVerified') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadVisitData();
    this.loadSelectOptions();

    this.visitDetailsService.getRefreshCurrentTab
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((response: boolean) => {
        this.loadVisitData();
      });

    this.visitDetailsService.getUpdatedVisitTab
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((response: VisitDetailsVisitInfo) => {
        this.visitData = response;
        this.updateForm(response);
      });
  }

  private loadSelectOptions(): void {
    const usersQuery = {
      profile_types: [UserType.Administrator, UserType.Coordinator],
      widget: 'm2m',
      limit: 1000
    };
    this.userService.getUsers(usersQuery)
      .pipe(untilDestroyed(this))
      .subscribe((supervisors) => {
        this.supervisorList = supervisors.results.map(el => ({ value: el.id, label: el.full_name }));
        this.cdr.detectChanges();
      }, error => this.notificationsService.showError(error));
  }

  private initForm(): void {
    this.form = this.fb.group({
      visitStart: null,
      visitEnd: null,
      overtimeHours: null,
      overtimeMinutes: null,
      supervisor: null,
      verified_by: null,
      timeVerified: null,

    });
  }

  private loadVisitData(): void {
    this.isLoading = true;

    if (this.visitData) {
      this.visitData = undefined;
      this.cdr.detectChanges();
    }

    this.visitDetailsService.getVisitInfo(this.visitId)
      .subscribe((response: VisitDetailsVisitInfo) => {
          this.isLoading = false;
          this.visitData = response;
          this.updateForm(response);
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.notificationsService.showError(error);
          this.cdr.detectChanges();
        });
  }

  private updateForm(response: VisitDetailsVisitInfo): void {
    this.notesList = response.notes.map(n => {
      return {
        date: n.created_at,
        reason_id: n.reason,
        reason: n.reason_detail.value,
        action_taken_id: n.action,
        action_taken: n.action_detail.value,
        notes: n.text,
        user: n.user_detail.full_name
      };
    });

    this.pocList = response.plan_of_care.plan_of_care_duties.map(d => {
      return {
        id: d.id,
        duty: d.duty,
        categoty: d.duty_details.category,
        categoryName: d.duty_details.category_name,
        dutyName: d.duty_details.name,
        dutyCode: d.duty_details.code,
        minutes: d.duty_details.duration,
        done: !!d.result?.status,
        not_done: !d.result?.status
      };
    });

    const sum = this.pocList.reduce((sum, poc) => {
      sum += poc.minutes;
      return sum;
    }, 0);
    this.visitsDuration = DurationPipe.durationInMinutesIntoTime(sum);

    this.form.patchValue({
      visitStart: response.fact_start_date_time,
      visitEnd: response.fact_end_date_time,
      overtimeHours: +response.overtime_hours === 0 ? '00' : pad(response.overtime_hours),
      overtimeMinutes: +response.overtime_minutes === 0 ? '00' : pad(response.overtime_minutes),
      supervisor: response.verification_by,
      verified_by: response.verification_type,
      timeVerified: response.verification_date_time
    });

    this.cdr.detectChanges();
  }

  private setPayloadForSave(): VisitDetailsInfoUpdate {
    const form = this.form.value;

    const pocData = this.pocList?.map(poc => {
      return {
        id: poc.id,
        duty: poc.duty,
        result_data: {
          status: poc.done ? 1 : 0
        }
      };
    });

    let overtimeHours = form.overtimeHours;
    let overtimeMinutes = form.overtimeMinutes;
    if (this.resetOverTime) {
      overtimeHours = '00';
      overtimeMinutes = '00';
    }
    return {
      fact_start_date_time: form.visitStart,
      fact_end_date_time: form.visitEnd,
      verification_date_time: form.timeVerified,
      verification_by: form.supervisor,
      verification_type: form.verified_by,
      overtime_hours: overtimeHours,
      overtime_minutes: overtimeMinutes,
      plan_of_care_data: {
        plan_of_care_duties_data: pocData || []
      }
    };
  }

  private patchVisitData(payload: any, control?: FormControl): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.patchVisitInfo(this.visitId, payload)
      .pipe(finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }), untilDestroyed((this)))
      .subscribe((response: VisitDetailsVisitInfo) => {
          control?.markAsPristine();
          this.notificationsService.showSuccess('visitDetails.tabVisit_action_update-success');
        },
        (error: HttpErrorResponse) => {
          this.formService.nonFieldErrors(error);
        });
  }

  save(onSuccess: () => void = () => null): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.updateVisitInfo(this.visitId, this.setPayloadForSave())
      .pipe(finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }), untilDestroyed((this)))
      .subscribe((response: VisitDetailsVisitInfo) => {
          this.form.markAsPristine();
          this.loadVisitData();
          this.notificationsService.showSuccess('visitDetails.tabVisit_action_update-success');
          onSuccess();
        },
        (error: HttpErrorResponse) => {
          this.formService.nonFieldErrors(error);
        });
  }

  changeStatus(status: VisitStatus): void {
    this.dispatchScreenService.changeStatus(this.visitData.id, { status })
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => this.formService.nonFieldErrors(error),
      });
  }

  addNote(note: VisitNote): void {
    this.patchVisitData({ notes_data: [note] });
  }

  changePOCCheckbox(event, pocId: number, fieldName: string): void {
    const poc = this.pocList.find(poc => poc.id === pocId);
    if (fieldName === 'done') {
      poc.done = event;
      poc.not_done = !event;
    } else {
      poc.done = !event;
      poc.not_done = event;
    }
  }

  visitStartTimeChange(event: string): void {
    this.visitStart.markAsDirty();
    this.visitStart.setValue(event);
    this.resetOverTime = true;

    const payload = {
      fact_start_date_time: this.visitStart.value ? this.visitStart.value : null
    };
    this.patchVisitData(payload, this.visitStart);
  }

  visitEndTimeChange(event: string): void {
    this.visitEnd.markAsDirty();
    this.visitEnd.setValue(event);
    this.resetOverTime = true;

    const payload = {
      fact_end_date_time: this.visitEnd.value ? this.visitEnd.value : null
    };
    this.patchVisitData(payload, this.visitEnd);
  }

  verificationDateChange(event): void {
    this.timeVerified.markAsDirty();
    this.form.patchValue({
      timeVerified: event
    });

    const paylod = {
      verification_date_time: this.timeVerified.value ? this.timeVerified.value : null
    };

    this.patchVisitData(paylod, this.timeVerified);
  }

  doubleNumber(time: number): string {
    return pad(time);
  }

  getEvvIconName(visitResponseType: VisitConfirmationType): string {
    return visitResponseType === VisitConfirmationType.Application ? 'phone-android' : 'call-24px';
  }
}
