<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div *ngIf="billingInfo" class="visit-details">
  <form
    class="visit-details__form form"
    novalidate
    autocomplete="off"
    [formGroup]="form">

    <section class="primary">
      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_primaryBillTo' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_primaryBillTo_tooltip' | translate">
          {{ billingInfo.payer_detail.name }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_billType' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billType_tooltip' | translate">
          {{ billingInfo.bill_type_value }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_serviceCode' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_serviceCode_tooltip' | translate">
          {{ billingInfo.service_code || '-' }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_modifier' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_modifier_tooltip' | translate">
          {{ billingInfo.modifier || '-' }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_totalHours' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_totalHours_tooltip' | translate">
          {{ billingInfo.total_duration | duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_overtimeHours' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_overtimeHours_tooltip' | translate">
          {{ billingInfo.overtime_duration | duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_billableHours' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billableHours_tooltip' | translate">
          {{ billingInfo.billable_duration | duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_billableUnits' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billableUnits_tooltip' | translate">
          {{ billingInfo.billable_units }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_billRates' | translate }}</span>
        <div class="form__value" [class.editable-field]="isContentEditable">
          <app-editable-content #billRateEditor formControlName="bill_rate" [disabled]="!isContentEditable">
            <div editContent>
              $
              <app-number-field [formControl]="billRateEditor.control" [max]="999.99"></app-number-field>
            </div>

            <span viewContent [matTooltip]="'visitDetails.tabBilling_field_billRate_tooltip' | translate">
              {{ billRateEditor.control.value | currency }}
            </span>
          </app-editable-content>
        </div>
      </div>

      <div *ngIf="billingInfo.bill_rate !== billRateEditor.control.value || billingInfo.bill_rate_notes" class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_notes' | translate }}</span>
        <div class="form__value editable-field">
          <app-editable-content
            #notesEditor
            formControlName="bill_rate_notes"
            [editTooltip]="'visitDetails.tabBilling_field_notes'">
            <textarea
              class="form-control"
              editContent
              #editTextarea
              [formControl]="notesEditor.control"
              [placeholder]="'visitDetails.tabBilling_field_notes' | translate">
            </textarea>

            <textarea
              viewContent
              readonly
              class="form-control"
              [value]="notesEditor.control.value"
              [placeholder]="'visitDetails.tabBilling_field_notes' | translate"
              (click)="notesEditor.startEdit(); editTextarea.focus()">
            </textarea>
          </app-editable-content>
        </div>
      </div>

      <div class="form__item amount">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_total' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_total_tooltip' | translate">
          {{ billingInfo.billable_sub_price | currency }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_billed' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billed_tooltip' | translate">
          {{ getYesNo(billingInfo.is_billed) }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_invoiceNumber' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_invoiceNumber_tooltip' | translate">
          {{ billingInfo.claim_detail?.number ?? '-' }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_invoiceBatch' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_invoiceBatch_tooltip' | translate">
          {{ billingInfo.claim_detail?.batch?.number ?? '-' }}
        </div>
      </div>
    </section>

    <section *ngIf="billingInfo.mileage_included_access" class="secondary" formGroupName="mileage_included">
      <app-mileage-form [rateEditable]="false"
                        [millageInfo]="billingInfo.mileage_included"
                        [disabled]="!isContentEditable"
      ></app-mileage-form>
    </section>

    <section *ngIf="billingInfo.travel_time_access" class="secondary" formGroupName="travel_time">
      <app-travel-time-form [rateEditable]="false"
                            [travelInfo]="billingInfo.travel_time"
                            [disabled]="!isContentEditable"
      ></app-travel-time-form>
    </section>

    <section class="total">
      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_totalAmount' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_totalAmount_tooltip' | translate">
          {{ billingInfo.billable_price | currency }}
        </div>
      </div>
    </section>
  </form>

  <div class="visit-details__save">
    <button
      *ngIf="isContentEditable"
      class="app-button app-button_blue app-button_spinner"
      matRipple
      matTooltipPosition="above"
      [matTooltip]="'buttons.save' | translate"
      [disabled]="!form.valid || form.pristine || isSaving"
      (click)="save()">
      <mat-spinner *ngIf="isSaving" class="spinner" diameter="14" strokeWidth="1"></mat-spinner>
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
