<mat-select class="multiselect-field {{ size }}"
            panelClass="app-multiselect-field-panel {{ size }}"
            multiple
            [class.fake-value]="showSelectedAllWithoutLoadedItems"
            [disableOptionCentering]="true"
            [placeholder]="showSelectedAllWithoutLoadedItems ? (inputPrefix + (allOptionLabel | translate)) : (placeholder | translate)"
            [disabled]="disabled"
            [class.invalid]="invalid"
            [(ngModel)]="value"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (selectionChange)="onOptionSelected()">
  <mat-select-trigger>
    <span class="selected-text">
      {{ inputPrefix }}

      <ng-container *ngIf="!showValueAsAllWhenEveryOptionSelected || !isAllOptionsSelected || (!optionsWereLoadedAtLeastOnce && !autoselectAllAfterFirstLoad); else allText">
        <ng-container *ngIf="!showValueAsCounter">
           {{ selectedLabels | arrayJoin: { delimiter: ' / ' } }}
        </ng-container>

        <ng-container *ngIf="showValueAsCounter">
          {{ value.length }}/{{ options.length }}
        </ng-container>
      </ng-container>
    </span>
  </mat-select-trigger>

  <mat-option class="option option_search" *ngIf="searchable">
    <ngx-mat-select-search [formControl]="searchControl"
                           [placeholderLabel]="'placeholders.typeToSearch' | translate"
    ></ngx-mat-select-search>
  </mat-option>

  <mat-option *ngIf="showToggleAllOption && options.length >= totalItemsWithoutFiltering" class="option option_all" disabled (click)="toggleAllOptions()">
    <mat-checkbox class="checkbox_toggle-all" [checked]="isAllOptionsSelected" disabled></mat-checkbox>

    <ng-container [ngTemplateOutlet]="allText"></ng-container>
  </mat-option>

  <mat-option *ngFor="let option of options; trackBy: trackByOption"
              class="option"
              [hidden]="loading"
              [value]="option[bindValue]"
              [disabled]="disableOptionsIfAllChecked ? isAllOptionsSelected : false">
    {{ translateLabel ? (option[bindLabel] | translate) : option[bindLabel]  }}
  </mat-option>

  <mat-option *ngIf="loading" class="option option_loading" disabled>
    <app-loading size="small"></app-loading>
  </mat-option>

  <mat-option *ngIf="!loading && !options?.length" class="option option_no-data" disabled>
    {{ 'commonNotifications.noResultsFound' | translate }}
  </mat-option>

<!-- Always keep selected options in DOM in case of filtering.
 Because when no options that has selected value then mat-select-trigger is not rendering -->
  <mat-option *ngFor="let item of selectedOptions" [hidden]="true" [value]="item[bindValue]"></mat-option>

</mat-select>

<ng-template #allText> {{ allOptionLabel | translate }} </ng-template>
